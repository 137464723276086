// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconFile(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "File";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "20",
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M4 1h12c.6 0 1 .4 1 1v13l-4 4H4a1 1 0 01-1-1V2c0-.6.4-1 1-1zm1 2v14h7l3-3V3H5zm2 2h6v2H7V5zm0 3h6v2H7V8zm0 3h6v2H7v-2z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconFile;

export {
  make ,
}
/* Icon Not a pure module */
