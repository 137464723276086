// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconView(props) {
  var __color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "View";
  var color = __color !== undefined ? __color : "DarkGray";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "20",
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("path", {
                                d: "M10,4 C13.5424723,4 16.5424723,6 19,10 C16.5424723,14 13.5424723,16 10,16 C6.45752767,16 3.45752767,14 1,10 C3.45752767,6 6.45752767,4 10,4 Z M10,6 C7.790861,6 6,7.790861 6,10 C6,12.209139 7.790861,14 10,14 C12.209139,14 14,12.209139 14,10 C14,7.790861 12.209139,6 10,6 Z M10,8 C11.1045695,8 12,8.8954305 12,10 C12,11.1045695 11.1045695,12 10,12 C8.8954305,12 8,11.1045695 8,10 C8,8.8954305 8.8954305,8 10,8 Z"
                              }),
                          fill: Icon.mapColor(color),
                          fillRule: "nonzero"
                        }),
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = IconView;

export {
  make ,
}
/* Icon Not a pure module */
