// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconInvalidFiles(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "InvalidFiles";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M5 1v1.155l-2.619 0.368 0.17 1.211-2.551 0.732 3.308 11.535 10.189-2.921 0.558-0.079h1.945v-12h-11zM3.929 14.879l-2.808-9.793 1.558-0.447 1.373 9.766 2.997-0.421-3.119 0.894zM4.822 13.382l-1.418-10.088 1.595-0.224v9.93h2.543l-2.721 0.382zM15 12h-9v-10h9v10zM13 8.939v1.061h-1.061l-1.439-1.439-1.439 1.439h-1.061v-1.061l1.439-1.439-1.439-1.439v-1.061h1.061l1.439 1.439 1.439-1.439h1.061v1.061l-1.439 1.439z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconInvalidFiles;

export {
  make ,
}
/* Icon Not a pure module */
