// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconAdditionalUpload(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Upload";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M-4-2h20v20H-4z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M0 14h12v2H0zM0 6l6-6 6 6H8v6H4V6z",
                            fill: Icon.mapColor(props.color),
                            fillRule: "nonzero"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd"
                  })
            });
}

var make = IconAdditionalUpload;

export {
  make ,
}
/* Icon Not a pure module */
