// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDelete(props) {
  var __viewBoxSize = props.viewBoxSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Delete";
  var viewBoxSize = __viewBoxSize !== undefined ? __viewBoxSize : "16";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: viewBoxSize,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    clipRule: "evenodd",
                    d: "M5.44444 1.77778H1V3.55556H15.2222V1.77778H10.7778L9.88889 0H6.33333L5.44444 1.77778ZM13.4444 4.44444H2.77778V14C2.77778 15.1046 3.67321 16 4.77778 16H11.4444C12.549 16 13.4444 15.1046 13.4444 14V4.44444Z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "evenodd"
                  })
            });
}

var make = IconDelete;

export {
  make ,
}
/* Icon Not a pure module */
