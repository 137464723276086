// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ErrorMessageScss from "./ErrorMessage.scss";

var css = ErrorMessageScss;

function ErrorMessage(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.error,
                    className
                  ])
            });
}

var make = ErrorMessage;

export {
  css ,
  make ,
}
/* css Not a pure module */
