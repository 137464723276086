// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Portal from "../../components/Portal/Portal.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as UploaderScss from "./Uploader.scss";
import * as UploaderOverlay from "./UploaderOverlay.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.js";

var css = UploaderScss;

function info(x) {
  switch (x) {
    case "Jpg" :
        return {
                mime: "image/jpeg",
                ext: [
                  ".jpg",
                  ".jpeg"
                ]
              };
    case "Png" :
        return {
                mime: "image/png",
                ext: [".png"]
              };
    case "Avi" :
        return {
                mime: "video/x-msvideo",
                ext: [".avi"]
              };
    case "Mp4" :
        return {
                mime: "video/mp4",
                ext: [".mp4"]
              };
    case "Mov" :
        return {
                mime: "video/quicktime",
                ext: [".mov"]
              };
    case "Wmv" :
        return {
                mime: "video/x-ms-wmv",
                ext: [".wmv"]
              };
    case "Pdf" :
        return {
                mime: "application/pdf",
                ext: [".pdf"]
              };
    case "Csv" :
        return {
                mime: "text/csv",
                ext: [".csv"]
              };
    case "Vsd" :
        return {
                mime: "application/vnd.visio",
                ext: [".vsd"]
              };
    case "Xls" :
        return {
                mime: "application/vnd.ms-excel",
                ext: [".xls"]
              };
    case "Xlsx" :
        return {
                mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ext: [".xlsx"]
              };
    case "Docx" :
        return {
                mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ext: [".docx"]
              };
    case "Doc" :
        return {
                mime: "application/msword",
                ext: [".doc"]
              };
    
  }
}

function fromMime(x) {
  switch (x) {
    case "application/msword" :
        return "Doc";
    case "application/pdf" :
        return "Pdf";
    case "application/vnd.ms-excel" :
        return "Xls";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :
        return "Xlsx";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" :
        return "Docx";
    case "application/vnd.visio" :
        return "Vsd";
    case "image/jpeg" :
        return "Jpg";
    case "image/png" :
        return "Png";
    case "text/csv" :
        return "Csv";
    case "video/mp4" :
        return "Mp4";
    case "video/quicktime" :
        return "Mov";
    case "video/x-ms-wmv" :
        return "Wmv";
    case "video/x-msvideo" :
        return "Avi";
    default:
      return PervasivesU.failwith("Unsupported mime type");
  }
}

var FileType = {
  info: info,
  fromMime: fromMime
};

var $$DataTransfer = {};

function containsFiles($$event) {
  var types = $$event.dataTransfer.types;
  if (types == null) {
    return false;
  }
  var valid = false;
  for(var i = 0 ,i_finish = types.length; i < i_finish; ++i){
    if (!valid) {
      valid = (function (__x) {
            return Js_string.includes("Files", __x);
          })(types[i]);
    }
    
  }
  return valid;
}

var Helpers = {
  containsFiles: containsFiles
};

function Uploader(props) {
  var setUploadHandler = props.setUploadHandler;
  var upload = props.upload;
  var __className = props.className;
  var allowed = props.allowed;
  var className = __className !== undefined ? __className : "";
  var counter = React.useRef(0);
  var input = React.useRef(null);
  var match = Hooks.useReducer("StandBy", (function (_state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "StartDragging" :
                  return {
                          TAG: "Update",
                          _0: "Dragging"
                        };
              case "FinishDragging" :
                  return {
                          TAG: "Update",
                          _0: "StandBy"
                        };
              case "SelectFiles" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var tmp = param.state;
                              if (typeof tmp !== "object" && tmp === "Dragging") {
                                return ;
                              }
                              Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(input.current), Webapi__Dom__Element.asHtmlElement), (function (prim) {
                                      prim.click();
                                    }));
                            })
                        };
              case "Cancel" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: "StandBy",
                          _1: (function (param) {
                              counter.current = 0;
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "ValidateOnDrop" :
                  var files = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              if (Belt_Array.every(files, (function (file) {
                                        return Belt_Array.map(allowed, (function (x) {
                                                        return info(x).mime;
                                                      })).includes(file.type);
                                      }))) {
                                return dispatch({
                                            TAG: "Upload",
                                            _0: files
                                          });
                              } else {
                                return dispatch({
                                            TAG: "ReportInvalidPayload",
                                            _0: "BadFileType"
                                          });
                              }
                            })
                        };
              case "Upload" :
                  var files$1 = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: "StandBy",
                          _1: (function (param) {
                              counter.current = 0;
                              upload(files$1);
                            })
                        };
              case "ReportInvalidPayload" :
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "InvalidPayload",
                            _0: action._0
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToDragEnter(function ($$event) {
                      if (!containsFiles($$event)) {
                        return ;
                      }
                      $$event.preventDefault();
                      counter.current = counter.current + 1 | 0;
                      var match = counter.current;
                      if (match !== 1) {
                        return ;
                      } else {
                        return dispatch("StartDragging");
                      }
                    });
        }), []);
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToDragOver(function ($$event) {
                      if (containsFiles($$event)) {
                        $$event.preventDefault();
                        $$event.dataTransfer.dropEffect = "copy";
                        return ;
                      }
                      
                    });
        }), []);
  React.useEffect(function () {
        return Events.Subscriptions.subscribeToDragLeave(function ($$event) {
                    if (!containsFiles($$event)) {
                      return ;
                    }
                    $$event.preventDefault();
                    counter.current = counter.current - 1 | 0;
                    var match = counter.current;
                    if (match !== 0) {
                      return ;
                    } else {
                      return dispatch("FinishDragging");
                    }
                  });
      });
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToDrop(function ($$event) {
                      if (!containsFiles($$event)) {
                        return ;
                      }
                      $$event.preventDefault();
                      var files = $$event.dataTransfer.files;
                      if (files == null) {
                        return dispatch({
                                    TAG: "ReportInvalidPayload",
                                    _0: "UnknownError"
                                  });
                      } else {
                        return dispatch({
                                    TAG: "ValidateOnDrop",
                                    _0: Array.from(files)
                                  });
                      }
                    });
        }), []);
  React.useEffect((function () {
          Belt_Option.map(setUploadHandler, (function (fn) {
                  return fn(function () {
                              dispatch("SelectFiles");
                            });
                }));
        }), [setUploadHandler]);
  var tmp;
  var tmp$1 = match[0];
  tmp = typeof tmp$1 !== "object" ? (
      tmp$1 === "Dragging" ? JsxRuntime.jsx(Portal.make, {
              children: JsxRuntime.jsx(UploaderOverlay.Dragging.make, {
                    cancelUpload: (function () {
                        dispatch("Cancel");
                      })
                  })
            }) : null
    ) : JsxRuntime.jsx(Portal.make, {
          children: JsxRuntime.jsx(UploaderOverlay.Invalid.make, {
                selectFiles: (function () {
                    dispatch("SelectFiles");
                  }),
                cancelUpload: (function () {
                    dispatch("Cancel");
                  })
              })
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: Cx.cx([
                            css.wrapper,
                            className
                          ]),
                      tabIndex: 0,
                      onClick: (function (param) {
                          dispatch("SelectFiles");
                        })
                    }),
                JsxRuntime.jsx("input", {
                      ref: Caml_option.some(input),
                      className: css.input,
                      accept: (function (__x) {
                            return Js_array.joinWith(",", __x);
                          })(Belt_Array.reduce(allowed, [], (function (acc, x) {
                                  return Belt_Array.concat(acc, info(x).ext);
                                }))),
                      multiple: props.multiple,
                      type: "file",
                      onChange: (function ($$event) {
                          dispatch({
                                TAG: "Upload",
                                _0: Array.from($$event.target.files)
                              });
                        })
                    }),
                tmp
              ]
            });
}

var make = Uploader;

export {
  css ,
  FileType ,
  $$DataTransfer ,
  Helpers ,
  make ,
}
/* css Not a pure module */
