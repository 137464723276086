// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconCalendar(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Calendar";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M1,3 L1,15 L15,15 L15,3 L1,3 Z M1,0 L15,0 C15.5522847,-1.01453063e-16 16,0.44771525 16,1 L16,15 C16,15.5522847 15.5522847,16 15,16 L1,16 C0.44771525,16 6.76353751e-17,15.5522847 0,15 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M10,10 L13,10 L13,13 L10,13 L10,10 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconCalendar;

export {
  make ,
}
/* Icon Not a pure module */
