// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconFiles(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Files";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M16 13v-12h-11v1.155l-2.619 0.368 0.17 1.211-2.551 0.732 3.308 11.535 10.189-2.921 0.558-0.079h1.945zM6 2h9v10h-9v-10zM5 3.070v9.93h2.543l-2.721 0.382-1.418-10.088 1.595-0.224zM3.929 14.879l-2.808-9.793 1.558-0.447 1.373 9.766 2.997-0.421-3.119 0.894z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconFiles;

export {
  make ,
}
/* Icon Not a pure module */
