// Generated by ReScript, PLEASE EDIT WITH CARE


var maxWidth = String(767);

var smMq = "only screen and (max-width: " + maxWidth + "px)";

var minWidth = String(1024);

var maxWidth$1 = String(1279);

var mdMq = "only screen and (min-width: " + minWidth + "px) and (max-width: " + maxWidth$1 + "px)";

var minWidth$1 = String(1024);

var lgMq = "only screen and (min-width: " + minWidth$1 + "px)";

var xsSlider = 320;

var xs = 375;

var xsSlider2 = 414;

var smallSlider = 767;

var small = 768;

var medium = 1024;

var large = 1280;

var xl = 1440;

export {
  xsSlider ,
  xs ,
  xsSlider2 ,
  smallSlider ,
  small ,
  medium ,
  large ,
  xl ,
  smMq ,
  mdMq ,
  lgMq ,
}
/* maxWidth Not a pure module */
