// Generated by ReScript, PLEASE EDIT WITH CARE


function subscribe(mq, handler) {
  mq.addListener(handler);
  return (function () {
            mq.removeListener(handler);
          });
}

export {
  subscribe ,
}
/* No side effect */
