// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Events from "../../../libs/Events.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as IconFiles from "../../icons/IconFiles.res.js";
import * as UploaderScss from "./Uploader.scss";
import * as IconInvalidFiles from "../../icons/IconInvalidFiles.res.js";
import * as JsxRuntime from "react/jsx-runtime";

var css = UploaderScss;

function UploaderOverlay$Dragging(props) {
  var cancelUpload = props.cancelUpload;
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, cancelUpload);
                    });
        }), [cancelUpload]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(IconFiles.make, {
                      size: "XXL",
                      color: "LightestGray",
                      className: css.icon
                    }),
                JsxRuntime.jsx("span", {
                      children: "C'mon, drop it right here!"
                    })
              ],
              className: css.overlay
            });
}

var Dragging = {
  make: UploaderOverlay$Dragging
};

function UploaderOverlay$Invalid(props) {
  var cancelUpload = props.cancelUpload;
  var selectFiles = props.selectFiles;
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, cancelUpload);
                    });
        }), [cancelUpload]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(IconInvalidFiles.make, {
                      size: "XXL",
                      color: "LightestGray",
                      className: css.icon
                    }),
                JsxRuntime.jsx("span", {
                      children: "Oops, wrong file type!"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Gray",
                              onClick: (function (param) {
                                  cancelUpload();
                                }),
                              children: "Cancel"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  selectFiles();
                                }),
                              children: "Reselect files"
                            })
                      ],
                      className: css.buttons
                    })
              ],
              className: css.overlay
            });
}

var Invalid = {
  make: UploaderOverlay$Invalid
};

export {
  css ,
  Dragging ,
  Invalid ,
}
/* css Not a pure module */
