// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BlogPost from "../../../models/BlogPost.res.js";
import * as IconFile from "../../../styleguide/icons/IconFile.res.js";
import * as IconView from "../../../styleguide/icons/IconView.res.js";
import * as IconDelete from "../../../styleguide/icons/IconDelete.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostNewFormScss from "./BlogPostNewForm.scss";

var css = BlogPostNewFormScss;

function BlogPostFeaturedImage$DocumentRow(props) {
  var onDelete = props.onDelete;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconFile.make, {
                              size: "LG",
                              color: "DarkGray"
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.fileName,
                              className: css.fileNameText
                            })
                      ],
                      className: css.flexContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("p", {
                              children: BlogPost.FeaturedImage.sizeText(props.byteSize),
                              className: css.fileSizeText
                            }),
                        JsxRuntime.jsx(IconView.make, {
                              size: "LG",
                              color: "DarkGray",
                              className: css.iconView
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDelete.make, {
                                    size: "LG",
                                    color: "DarkGray",
                                    viewBoxSize: "20"
                                  }),
                              className: css.iconDelete,
                              onClick: (function (param) {
                                  onDelete();
                                })
                            })
                      ],
                      className: css.flexContainer
                    })
              ],
              className: css.documentRow
            });
}

var DocumentRow = {
  make: BlogPostFeaturedImage$DocumentRow
};

function BlogPostFeaturedImage(props) {
  var removeImage = props.removeImage;
  var featuredImage = props.featuredImage;
  var tmp;
  if (featuredImage !== undefined) {
    var featuredImage$1 = Caml_option.valFromOption(featuredImage);
    tmp = JsxRuntime.jsx(BlogPostFeaturedImage$DocumentRow, {
          fileName: featuredImage$1.name,
          byteSize: featuredImage$1.size | 0,
          onDelete: (function () {
              removeImage(featuredImage$1);
            })
        }, "recently-uploaded-document-" + featuredImage$1.name);
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = BlogPostFeaturedImage;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
