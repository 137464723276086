// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Make(Config) {
  var ctx = React.createContext(Config.defaultValue);
  var make = ctx.Provider;
  var Provider = {
    make: make
  };
  return {
          ctx: ctx,
          Provider: Provider
        };
}

export {
  Make ,
}
/* react Not a pure module */
