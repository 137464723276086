// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Screens from "../styleguide/styles/Screens.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$MediaQueryList from "./MediaQueryList.res.js";

function usePrevious(v) {
  var x = React.useRef(undefined);
  React.useEffect(function () {
        x.current = Caml_option.some(v);
      });
  return x.current;
}

function useReducer(initialState, reducer) {
  var match = React.useReducer((function (fullState, action) {
          var sideEffects = fullState.sideEffects;
          var state = reducer(fullState.state, action);
          if (typeof state !== "object") {
            return fullState;
          }
          switch (state.TAG) {
            case "Update" :
                return {
                        state: state._0,
                        sideEffects: fullState.sideEffects
                      };
            case "UpdateWithSideEffects" :
                return {
                        state: state._0,
                        sideEffects: {
                          contents: Belt_Array.concat(sideEffects.contents, [state._1])
                        }
                      };
            case "SideEffects" :
                return {
                        state: fullState.state,
                        sideEffects: {
                          contents: Belt_Array.concat(sideEffects.contents, [state._0])
                        }
                      };
            
          }
        }), {
        state: initialState,
        sideEffects: {
          contents: []
        }
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length !== 0) {
            Belt_Array.forEach(sideEffects.contents, (function (fn) {
                    fn({
                          state: state,
                          dispatch: dispatch
                        });
                  }));
            sideEffects.contents = [];
          }
          
        }), [sideEffects.contents]);
  return [
          state,
          dispatch
        ];
}

function useScreenSize() {
  var match = React.useState(function () {
        return "LG";
      });
  var setScreenSize = match[1];
  var mediaQueries = [
    {
      query: Screens.smMq,
      size: "SM"
    },
    {
      query: Screens.mdMq,
      size: "MD"
    },
    {
      query: Screens.lgMq,
      size: "LG"
    }
  ];
  React.useEffect((function () {
          var listeners = Belt_Array.map(mediaQueries, (function (param) {
                  return [
                          window.matchMedia(param.query),
                          param.size
                        ];
                }));
          var idx = listeners.findIndex(function (param) {
                return param[0].matches;
              });
          var match = Belt_Array.get(listeners, idx);
          if (match !== undefined) {
            var size = match[1];
            setScreenSize(function (param) {
                  return size;
                });
          }
          var unsubscribeListeners = Belt_Array.map(listeners, (function (param) {
                  var size = param[1];
                  return $$MediaQueryList.subscribe(param[0], (function ($$event) {
                                if ($$event.matches) {
                                  return setScreenSize(function (param) {
                                              return size;
                                            });
                                }
                                
                              }));
                }));
          return (function () {
                    Belt_Array.forEach(unsubscribeListeners, (function (listener) {
                            if (listener !== undefined) {
                              return listener();
                            }
                            
                          }));
                  });
        }), [mediaQueries]);
  return match[0];
}

export {
  usePrevious ,
  useReducer ,
  useScreenSize ,
}
/* react Not a pure module */
