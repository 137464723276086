// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconOk from "../../icons/IconOk.res.js";
import * as TextareaScss from "./Textarea.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TextareaScss;

function Textarea(props) {
  var __wrapperClassName = props.wrapperClassName;
  var __className = props.className;
  var __autoFocus = props.autoFocus;
  var status = props.status;
  var __disabled = props.disabled;
  var __placeholder = props.placeholder;
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var disabled = __disabled !== undefined ? __disabled : false;
  var autoFocus = __autoFocus !== undefined ? __autoFocus : false;
  var className = __className !== undefined ? __className : "";
  var wrapperClassName = __wrapperClassName !== undefined ? __wrapperClassName : "";
  var tmp;
  tmp = status !== undefined && status !== "Valid" ? css.error : "";
  var tmp$1;
  tmp$1 = status === "Valid" ? css.withRightSideAddon : "";
  var tmp$2;
  tmp$2 = status === "Valid" ? JsxRuntime.jsx("label", {
          children: JsxRuntime.jsx(IconOk.make, {
                size: "MD",
                color: "Teal"
              }),
          className: css.icon
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("textarea", {
                      ref: props.setRef,
                      className: Cx.cx([
                            css.textarea,
                            disabled ? css.disabled : "",
                            tmp,
                            tmp$1,
                            className
                          ]),
                      id: props.id,
                      autoFocus: autoFocus,
                      disabled: disabled,
                      placeholder: placeholder,
                      rows: props.rows,
                      value: props.value,
                      onBlur: props.onBlur,
                      onChange: props.onChange
                    }),
                tmp$2
              ],
              className: Cx.cx([
                    css.wrapper,
                    wrapperClassName
                  ])
            });
}

var make = Textarea;

export {
  css ,
  make ,
}
/* css Not a pure module */
