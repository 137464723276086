// Generated by ReScript, PLEASE EDIT WITH CARE


function fromString(x) {
  switch (x) {
    case " " :
        return "Space";
    case "," :
        return "Comma";
    case "Enter" :
        return "Enter";
    case "Escape" :
        return "Escape";
    default:
      return "Other";
  }
}

var Key = {
  fromString: fromString
};

function key($$event) {
  return fromString($$event.key);
}

function onEsc($$event, fn) {
  var match = fromString($$event.key);
  if (match === "Escape") {
    return fn();
  }
  
}

var Dom = {
  key: key,
  onEsc: onEsc
};

function key$1($$event) {
  return fromString($$event.key);
}

function onSpaceOrEnter($$event, fn) {
  var match = fromString($$event.key);
  switch (match) {
    case "Enter" :
    case "Space" :
        return fn();
    default:
      return ;
  }
}

function onEsc$1($$event, fn) {
  var match = fromString($$event.key);
  if (match === "Escape") {
    return fn();
  }
  
}

function onComma($$event, fn) {
  var match = fromString($$event.key);
  if (match === "Comma") {
    return fn();
  }
  
}

var React = {
  key: key$1,
  onSpaceOrEnter: onSpaceOrEnter,
  onEsc: onEsc$1,
  onComma: onComma
};

export {
  Key ,
  Dom ,
  React ,
}
/* No side effect */
