// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as ReactContext from "../../../bindings/ReactContext.res.js";
import * as PopoverScss from "./Popover.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = PopoverScss;

var Position = {};

function fail() {
  return PervasivesU.failwith("Popover.Context is not set");
}

var defaultValue = {
  shown: false,
  show: fail,
  hide: fail,
  toggle: fail,
  bodyRef: undefined,
  triggerRef: undefined
};

var include = ReactContext.Make({
      defaultValue: defaultValue
    });

var ctx = include.ctx;

var Provider = include.Provider;

var Context = {
  ctx: ctx,
  Provider: Provider
};

var initialState = {
  shown: false
};

function Popover$Container(props) {
  var onHide = props.onHide;
  var onShow = props.onShow;
  var __className = props.className;
  var __id = props.id;
  var id = __id !== undefined ? __id : "";
  var className = __className !== undefined ? __className : "";
  var body = React.useRef(null);
  var trigger = React.useRef(null);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          switch (action) {
            case "Show" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          shown: true
                        },
                        _1: (function (param) {
                            if (onShow !== undefined) {
                              return onShow();
                            }
                            
                          })
                      };
            case "Hide" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          shown: false
                        },
                        _1: (function (param) {
                            if (onHide !== undefined) {
                              return onHide();
                            }
                            
                          })
                      };
            case "Toggle" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          shown: !state.shown
                        },
                        _1: (function (param) {
                            var match = param.state.shown;
                            if (match) {
                              if (onShow !== undefined) {
                                return onShow();
                              } else {
                                return ;
                              }
                            } else if (onHide !== undefined) {
                              return onHide();
                            } else {
                              return ;
                            }
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, (function () {
                              if (state.shown) {
                                return dispatch("Hide");
                              }
                              
                            }));
                    });
        }), [state.shown]);
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToClicks(function ($$event) {
                      var match = body.current;
                      var match$1 = trigger.current;
                      if (!state.shown) {
                        return ;
                      }
                      if (match$1 == null) {
                        return ;
                      }
                      if (match == null) {
                        return ;
                      }
                      var target = $$event.target;
                      if (!match.contains(target) && !match$1.contains(target)) {
                        return dispatch("Hide");
                      }
                      
                    });
        }), [
        state.shown,
        body,
        trigger
      ]);
  return JsxRuntime.jsx(Provider.make, {
              value: {
                shown: state.shown,
                show: (function () {
                    dispatch("Show");
                  }),
                hide: (function () {
                    dispatch("Hide");
                  }),
                toggle: (function () {
                    dispatch("Toggle");
                  }),
                bodyRef: body,
                triggerRef: trigger
              },
              children: JsxRuntime.jsx("div", {
                    children: props.children,
                    className: Cx.cx([
                          css.container,
                          className
                        ]),
                    id: id
                  })
            });
}

var Container = {
  initialState: initialState,
  make: Popover$Container
};

function Popover$Trigger(props) {
  var children = props.children;
  var className = props.className;
  var __on = props.on;
  var on = __on !== undefined ? __on : "Click";
  var ctx$1 = React.useContext(ctx);
  if (on === "Focus") {
    return JsxRuntime.jsx("div", {
                children: children,
                ref: Caml_option.some(ctx$1.triggerRef),
                className: className,
                tabIndex: -1,
                onFocus: (function (param) {
                    ctx$1.toggle();
                  })
              });
  } else {
    return JsxRuntime.jsx("div", {
                children: children,
                ref: Caml_option.some(ctx$1.triggerRef),
                className: className,
                tabIndex: -1,
                onClick: (function (param) {
                    ctx$1.toggle();
                  })
              });
  }
}

var Trigger = {
  make: Popover$Trigger
};

function Popover$Body(props) {
  var __className = props.className;
  var position = props.position;
  var className = __className !== undefined ? __className : "";
  var ctx$1 = React.useContext(ctx);
  if (!ctx$1.shown) {
    return null;
  }
  var tmp;
  if (typeof position !== "object") {
    tmp = position === "OnLeft" ? css.onLeft : css.onRight;
  } else if (position.TAG === "Above") {
    switch (position._0) {
      case "LeftEdge" :
          tmp = css.aboveLeftEdge;
          break;
      case "Center" :
          tmp = css.aboveCenter;
          break;
      case "RightEdge" :
          tmp = css.aboveRightEdge;
          break;
      
    }
  } else {
    switch (position._0) {
      case "LeftEdge" :
          tmp = css.belowLeftEdge;
          break;
      case "Center" :
          tmp = css.belowCenter;
          break;
      case "RightEdge" :
          tmp = css.belowRightEdge;
          break;
      
    }
  }
  return JsxRuntime.jsx("div", {
              children: props.children,
              ref: Caml_option.some(ctx$1.bodyRef),
              className: Cx.cx([
                    css.body,
                    tmp,
                    className
                  ])
            });
}

var Body = {
  make: Popover$Body
};

var make = Popover$Container;

export {
  css ,
  Position ,
  Context ,
  Container ,
  Trigger ,
  Body ,
  initialState ,
  make ,
}
/* css Not a pure module */
