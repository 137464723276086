// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Popover from "../../components/Popover/Popover.res.js";
import * as TextField from "../TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconCalendar from "../../icons/IconCalendar.res.js";
import Format from "date-fns/format";
import ReactDayPicker from "react-day-picker";
import * as DatePickerScss from "./DatePicker.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = DatePickerScss;

function DatePicker$Body(props) {
  var onDayChange = props.onDayChange;
  var value = props.value;
  var ctx = React.useContext(Popover.Context.ctx);
  return JsxRuntime.jsx(ReactDayPicker, {
              initialMonth: value,
              fromMonth: props.fromMonth,
              numberOfMonths: 1,
              showOutsideDays: true,
              selectedDays: Belt_Option.map(value, (function (x) {
                      return {
                              TAG: "Date",
                              _0: x
                            };
                    })),
              classNames: Caml_option.some(css),
              onDayClick: (function (date, modifiers, $$event) {
                  onDayChange(date, modifiers, $$event);
                  ctx.hide();
                }),
              disabledDays: props.disabledDays
            });
}

var Body = {
  make: DatePicker$Body
};

function DatePicker(props) {
  var position = props.position;
  var value = props.value;
  return JsxRuntime.jsxs(Popover.make, {
              className: css.box,
              onShow: props.onShow,
              onHide: props.onHide,
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      on: "Focus",
                      children: JsxRuntime.jsx(TextField.make, {
                            id: props.id,
                            value: Belt_Option.getWithDefault(Belt_Option.map(value, (function (__x) {
                                        return Format(__x, "MM/dd/yyyy");
                                      })), ""),
                            status: props.status,
                            icon: IconCalendar,
                            className: props.className,
                            onBlur: props.onBlur,
                            onChange: (function (prim) {
                                
                              })
                          })
                    }),
                JsxRuntime.jsx(Popover.Body.make, {
                      position: position !== undefined ? Belt_Option.getExn(position) : ({
                            TAG: "Below",
                            _0: "Center"
                          }),
                      className: css.zIndex,
                      children: JsxRuntime.jsx(DatePicker$Body, {
                            value: value,
                            fromMonth: props.fromMonth,
                            disabledDays: props.disabledDays,
                            onDayChange: props.onDayChange
                          })
                    })
              ]
            });
}

var make = DatePicker;

export {
  css ,
  Body ,
  make ,
}
/* css Not a pure module */
